// Pulse 5.3.2
// Bootswatch

$theme: "pulse" !default;

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #3459e6 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #da292e !default;
$orange:  #f8765f !default;
$yellow:  #f4bd61 !default;
$green:   #2fb380 !default;
$teal:    #20c997 !default;
$cyan:    #287bb5 !default;

$primary:       $blue !default;
$secondary:     $white !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$min-contrast-ratio:   2.1 !default;

// Options

$enable-rounded: false !default;

// Body

$body-color:                $gray-700 !default;

// Links

$link-hover-color:          $primary !default;

// Tables

$table-border-color:          rgba(0, 0, 0, .05) !default;

// Forms

$input-focus-border-color:              $primary !default;

// Dropdowns

$dropdown-link-color:               $gray-700 !default;
$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $primary !default;

// Navs

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  $primary !default;

// Navbar

$navbar-padding-y:                  1.2rem !default;

// Progress bars

$progress-bg:                       $gray-300 !default;
$progress-bar-bg:                   $primary !default;

// List group

$list-group-bg:                     $gray-900 !default;
$list-group-border-color:           transparent !default;
$list-group-hover-bg:               lighten($list-group-bg, 10%) !default;
$list-group-active-color:           $white !default;
$list-group-active-bg:              $list-group-bg !default;
$list-group-disabled-color:         lighten($list-group-bg, 30%) !default;
